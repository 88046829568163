import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const JunkRemoval = () => {
	const TheTitle = "Junk Removal Services"
	const TheDescription = "Everyone starts to build up unwanted materials given enough time. Clearing it up and disposing of it all appropriately can be a daunting task. If you’ve been avoiding the side of your house with all the piled wood and tree branches hoping it will eventually disappear. You’re in luck! Give us a call and before you know it we’ll makes sure it does disappear."
	const TheCanonical = "https://gardenescape.ca/services/junk-removal/"

	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			{/*<div className="grid grid-cols-1 items-center justify-center mb-16">*/}
			<div className="serviceGrid">
				<StaticImage
							className="serviceGrid_boxA"
							src="../../images/home/leaves.png"
				             alt="background placeholder"
				             layout="fullWidth" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>
			<div className="mainWrapper my-16">
				<h2>NEED THAT RUBBISH REMOVED?</h2>
				<p>Everyone starts to build up unwanted materials given enough time. Clearing it up and disposing of it
					all appropriately can be a daunting task. If you’ve been avoiding the side of your house with all
					the piled wood and tree branches hoping it will eventually disappear. You’re in luck! Give us a call
					and before you know it we’ll makes sure it does disappear.</p>
				<h2>WHAT CAN WE CLEAN UP?</h2>
				<p>We specialize in the removal of yard waste, wood, branches green waste and plants and compost. If you
					need other materials removed just mention what they are and we can arrange a no obligation free
					quote especially for those other material types.</p>
				<h2>HOW DO I PROGRESS?</h2>
				<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan
					that’ll best fit your yard goals and budget. Just contact us through our website, phone or
					email.</p>
				<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
				<p>We are happy to help you with all things related to beautifying your property, some other major
					services we offer are; Regular lawn mow, fertilizing, Seasonal Yard cleanup, Hedge and Shrub
					pruning, Aeration and Power Raking, Power washing.</p>
			</div>
		</Layout>
	)
}

export default JunkRemoval